import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  // Start with the menu collapsed so that it does not
  // appear initially when the page loads on a small screen
  isMenuCollapsed:boolean = true;

  env = environment;

  rows = new Array(5);

  constructor(public authService:AuthService) { }

  ngOnInit() {
  }

}
