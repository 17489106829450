import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-a-header',
  templateUrl: './a-header.component.html',
  styleUrls: ['./a-header.component.css']
})
export class AHeaderComponent implements OnInit {

  // Start with the menu collapsed so that it does not
  // appear initially when the page loads on a small screen
  isMenuCollapsed:boolean = true;

  constructor(public authService:AuthService) { }

  ngOnInit() { }

  signOut() {
    console.log("a-header signOut");
    this.authService.logOut();
  }

}
