import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Student, EducationalCategory } from '../interfaces';
import { BackendService } from '../backend.service';
import { CustomValidators } from '../custom.validators';


@Component({
  selector: 'app-a-create-student',
  templateUrl: './a-create-student.component.html',
  styleUrls: ['./a-create-student.component.css']
})
export class ACreateStudentComponent implements OnInit {

  @Output() onSuccessfulSubmission = new EventEmitter<Student>();

  genders = [ "Gender", "female", "male", "other" ];
  // classes: EducationalCategory[];
  classes = [];
  
  errorMessage: String = " ";

  newStudent: Student = {
    id : null,
    fName: "",
    lName: "",
    gender: null,
    teacherId: null,
    classId: null,
    projectId: null,
    cert: null
  }


  createStudentForm: FormGroup;

  constructor(private backendService:BackendService) { }

  ngOnInit() {

    this.getClasses();

    this.createStudentForm = new FormGroup ({
      fName: new FormControl(this.newStudent.fName, [CustomValidators.required]),
      lName: new FormControl(this.newStudent.lName, [CustomValidators.required]),
      gender: new FormControl(this.genders),
      classId: new FormControl(this.classes)
    });

    //set custom validator
    this.createStudentForm.get('gender').setValidators([
      CustomValidators.excludedValue(this.genders[0])
    ]);

    this.initForm();

  }
  
  initForm(){
    // set to hint values
    this.createStudentForm.get('gender').setValue(this.genders[0]);
    this.createStudentForm.get('classId').setValue("notaclass");
    this.createStudentForm.get('fName').setValue("");
    this.createStudentForm.get('lName').setValue("");
  }
  
  getClasses(){
    this.backendService.getEducationalCategories().subscribe(
      (categories: EducationalCategory[]) => {
        this.setUpClassesDropdown(categories);
      },
      error => {
        console.log('Failed to get the classes');
        console.log(error);
    });
  }

  setUpClassesDropdown(classes: EducationalCategory[]) {

    // build set of class.group properties and transform it into array
    let listOfGroups = [...new Set(classes.map( x => x.group))];
    console.log("listOfGroups", listOfGroups);

    // nest items of classes array in the matching groups, they match
    let grouppedClasses = listOfGroups.map(
      x => ({
        groupLabel: x,
        items: classes.filter( y => y.group === x) 
      })
    );

    // Add the hint
    //grouppedClasses.unshift({group: "Select Class", items: [{ id: 0, name: "Select Class", group: "Select Class"}]});

    this.classes = grouppedClasses;

    console.warn("groupped classes:", this.classes);

    this.createStudentForm.get('classId').setValidators([
      CustomValidators.required,
      // CustomValidators.excludedValue('17')
    ]);
  }

  onSubmitt(w) {
    
    console.log(this.createStudentForm.value);
    console.log("w:",w);
    console.log("-0-----------------");
    console.log(this.createStudentForm);
  }

  onSubmit() {
    console.log("in onSubmit");
    console.warn(this.createStudentForm.value);
    
      // let newStudent:Student = {
      //   id: null,
      //   fName: this.createStudentForm.value.fName,
      //   lName: this.createStudentForm.value.lName,
      //   gender: this.createStudentForm.value.gender,
      //   teacherId: null,
      //   classId: +this.createStudentForm.value.classId,
      //   projectId: null
      // }

      let newStudent:Student = this.createStudentForm.value;
      console.log("partial new student:");
      console.log(newStudent);
      newStudent.teacherId = 1;
      newStudent.projectId = null;
      // unary "+" converts a string to a number (and on failure produces 0, not NaN)
      newStudent.classId = +newStudent.classId; 
      console.log("complete new student:");
      console.log(newStudent);


    this.backendService.addStudent( 
        this.createStudentForm.value as Student
      ).subscribe(
      (student: Student) => {
        console.log("Successful backend response: " + JSON.stringify(student));
        this.initForm();
        this.onSuccessfulSubmission.emit(student);
      },
      error => {
        console.log('Failed to save the new student');
        console.log(error);
        this.onFailedSubmission();
      }
    );
  }

  onFailedSubmission() {
    //TODO: add student details & option to resend data
    this.errorMessage = "Failed to save the new student";
    setTimeout(()=>{
      this.errorMessage = "";
    },5000);
  }
}
