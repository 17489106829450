import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assign-students',
  templateUrl: './assign-students.component.html',
  styleUrls: ['./assign-students.component.css']
})
export class AssignStudentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
