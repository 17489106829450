import { Component, OnInit } from '@angular/core';
import { ScratchConfig } from '../config';

@Component({
  selector: 'app-a-footer',
  templateUrl: './a-footer.component.html',
  styleUrls: ['./a-footer.component.css']
})
export class AFooterComponent implements OnInit {

  readonly ADDRESS = `${ScratchConfig.ADDRESS1}, ${ScratchConfig.ADDRESS2}, ${ScratchConfig.ADDRESS3}`;
  EMAIL = ScratchConfig.EMAIL;
  PHONE = ScratchConfig.PHONE;
  LINKEDIN = ScratchConfig.LINKEDIN;
  TWITTER = ScratchConfig.TWITTER;
  FACEBOOK = ScratchConfig.FACEBOOK;
  EVENTS = ScratchConfig.EVENTS;
  ABOUT = ScratchConfig.ABOUT;
  PRIVACY = ScratchConfig.PRIVACY;

  constructor() { }

  ngOnInit() {
  }

  // getAddress() : string {
  //   return `${Config.ADDRESS1}, ${Config.ADDRESS2}, ${Config.ADDRESS3}`;
  // }

}
