export class ScratchConfig {
    public static readonly PHONE = "01 23 777 88";
    public static readonly ADDRESS1 = "Irish Computer Society";
    public static readonly ADDRESS2 = "20 Harcourt Street";
    public static readonly ADDRESS3 = "Dublin 2";
 
    public static readonly EMAIL = "info@techweek.ie";
    public static readonly LINKEDIN = "http://www.linkedin.com/company/ics-skills?trk=cws-cpw-coname-0-0";
    public static readonly TWITTER = "https://twitter.com/irishcompsoc";
    public static readonly FACEBOOK = "https://www.facebook.com/IrishComputerSociety";
     
    public static readonly EVENTS = "http://www.ics.ie/index.php?option=com_content&view=article&id=530&Itemid=241";
    public static readonly ABOUT = "http://www.ics.ie/index.php?option=com_content&view=article&id=47&Itemid=244";
    public static readonly PRIVACY = "http://www.ics-skills.ie/corporate/privacy_statement.php";
    // public static TWEETER = "01 23 777 99";
}