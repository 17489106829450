import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-profile',
  templateUrl: './a-profile.component.html',
  styleUrls: ['./a-profile.component.css']
})
export class AProfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
