import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Student, Teacher, Organisation, Project, EducationalCategory, County, UserDetails, Cert } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  // authentication
  private loggedIn = false;
  private token: string;
 
   setLoggedIn(loggedIn: boolean, token?: string) {
     this.loggedIn = loggedIn;
     this.token = token;
   }

  //////

  private teacherId = 1;
  private organisationId = 1;

  //////// private static azureOldRoot = 'https://scratch-api.azurewebsites.net';
  private static jsonServerRoot = 'http://localhost:3000';
  private static localhostLaravelRoot = 'http://localhost:8000/api';
  private static localhostRoot = 'https://localhost:5001';
  private static azureRoot = 'https://ics-scratch-api.azurewebsites.net';
  
  // private static apiRoot = BackendService.localhostRoot;
  private static apiRoot = BackendService.azureRoot;






  // buffering
  private static requested:boolean[] = [];
  private static categories: EducationalCategory[];
  
  // httpOptions = {
  //   headers: new HttpHeaders({ 
  //     'Content-Type': 'application/json',
  //     // 'Authorization': 'my-auth-token'
  //   })
  // };

  // private getHttpOptions() {

  //   let authHeader = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  //   let httpOptions = {
  //     headers: new HttpHeaders({ 
  //       'Content-Type': 'application/json',
  //       'Authorization': `${authHeader}`
  //     })
  //   };

  //   console.log("HTTP OPTIONS:");
  //   console.log(httpOptions);


  //   return { headers: new HttpHeaders({ 
  //     'Content-Type': 'application/json',
  //     'Authorization': `${authHeader}`
  //   })}
  // }

  constructor(private httpClient: HttpClient) { }

  getUserDetails(): Observable<UserDetails>{

    // let url:string = `${BackendService.apiRoot}/user-details`;
    let url:string = `${BackendService.apiRoot}/user-details`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .get<UserDetails>(url, {responseType: 'json', headers: header})
      .pipe (catchError(this.handleError));
  }

  getCounties() : Observable<County[]> {
    // let url:string = `${BackendService.apiRoot}/counties`;//switch to convertMockApiCountiesToCounties()!
    let url:string = `${BackendService.apiRoot}/counties`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .get<County[]>(url, {
        responseType: 'json',
        headers: header
      })
      .pipe(
        catchError(this.handleError),
        map(data => this.convertToCounties(data))
    );
  } 
  private convertToCounties(input)  {
    return input.map((item) =>({
      id: item.id, value: item.title
    }));
  }
  private convertMockApiCountiesToCounties(input)  {
    console.log("json-server api counties:", input);
    return input.map((item, index) =>({
      id: index, value: item
    }));
  }


  getEducationalCategories(): Observable<EducationalCategory[]> {
    // let url:string = `${BackendService.apiRoot}/educationalCategories`;
    let url:string = `${BackendService.apiRoot}/categories`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .get<EducationalCategory[]>(url, {
        responseType: 'json',
        headers: header
      })
      .pipe(
        tap(data => BackendService.categories = data),
        catchError(this.handleError)
      );
  }

  getStudents(): Observable<Student[]> {

    // let url:string = `${BackendService.apiRoot}/students`;
    let url:string = `${BackendService.apiRoot}/students`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    
    // console.log("getStudents header: " + JSON.stringify(header));

    return this.httpClient
      .get<Student[]>(url, {
        responseType: 'json',
        headers: header
      })
      .pipe(
        catchError(this.handleError)
      );
  }


  // TODO: build an interceptor, so type safety is not compromised
  // private makeRequest (method: string, url:string) {

  //   let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  //   return this.httpClient
  //   .get<Student[]>(url, {
  //     responseType: 'json',
  //     headers: header
  //   })
  //   .pipe(
  //     catchError(this.handleError)
  //   );

  // }

  updateTeacher(teacher: Teacher) {
    console.log(`updateTeacher:`, teacher);
    // let url:string = `${BackendService.apiRoot}/update`;
    let url:string = `${BackendService.apiRoot}/teachers`;

    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    // let user = this.userFromTeacher(teacher);
    return this.httpClient
    // .put(url, user, {headers : header})
    .put(url, teacher, {headers : header})
    .pipe(
      catchError(this.handleError)
    );
  }

  updateOrganisation(org: Organisation): Observable<Organisation> {

    console.log("b.serv: ");
    console.log(org);

    // request fails without the trailing "/0" despite the fact that backend don't expect an id
    // error: method not allowed (sounds like wrong http request type?)
    // let url:string = `${BackendService.apiRoot}/organisations/0`
    let url:string = `${BackendService.apiRoot}/centres`;


    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .put<Organisation>(url, org, {headers : header})
      .pipe(
        catchError(this.handleError)
      );
  }


  updateStudent(student: Student): Observable<any> {

    // let url:string = `${BackendService.apiRoot}/students/${student.id}`
    let url:string = `${BackendService.apiRoot}/students/`

    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    
    // somehow no need to JSON.stringify the student object
    // let studentJson:string = JSON.stringify(student);
    // console.log(studentJson);

    return this.httpClient
      
      .put<Student>(url, student, {headers : header})
      .pipe(
        catchError(this.handleError)
      );
    
    // This:
    //    const headers = new HttpHeaders()
    //    .set("Content-Type", "application/json");
    // plus this form of the put request:
    //    .put(url, studentJson, {headers})
    // works too.
  }

  addStudent(student: Student):Observable<Student> {

    console.log("In service addStudent");
    console.log(student);

    // let url:string = `${BackendService.apiRoot}/students`;
    let url:string = `${BackendService.apiRoot}/students`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .post<Student>(url, student, {headers: header})
      .pipe(
        catchError(this.handleError)
      );
  }


  addOrganisation(organisation:Organisation):Observable<Organisation> {

    // let url:string = `${BackendService.apiRoot}/organisations`;
    let url:string = `${BackendService.apiRoot}/centres`;

    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
    .post<Organisation>(url, organisation, {headers: header})
    // .pipe(
    //   catchError(this.handleError)
    // );
  }

  // addTeacherr(teacher:Teacher):Observable<Teacher>{
  //   // How to check if teacher already exists?? - validate against an email probably??
  //   let url:string = `${BackendService.apiRoot}/teachers`;
  //   let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  //   return this.httpClient
  //   .post<Teacher>(url, teacher, {headers: header})
  //   .pipe(
  //     catchError(this.handleError)
  //   );
  // }

  private userFromTeacher(teacher:Teacher) {
    return {
      name: teacher.fName,
      surname: teacher.lName,
      email: teacher.email,
      phone: teacher.phone,
      password: teacher.password,
      password_confirmation: teacher.password,
      organisation_id: teacher.organisationId,
    }
  }

  addTeacher(teacher:Teacher){
    // How to check if teacher already exists?? - validate against an email probably?
    // let url:string = `${BackendService.apiRoot}/register`;
    let url:string = `${BackendService.apiRoot}/teachers`;

    //TODO: remove registration from admin section (and get rid of the token?)
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    let user = this.userFromTeacher(teacher);
    // {
    //   name: teacher.fName,
    //   surname: teacher.lName,
    //   email: teacher.email,
    //   phone: teacher.phone,
    //   password: teacher.password,
    //   password_confirmation: teacher.password,
    //   organisation_id: teacher.organisationId,
    //  };

    console.log("backendService.addTeacher is posting this:");
    // console.log(user);
    console.log(teacher);

    return this.httpClient
    // .post(url, user, {headers: header})
    .post(url, teacher, {headers: header})
    .pipe(
      catchError(this.handleError)
    );
  }

  requestCert(student:Student): Observable<Cert> {
    // let url:string = `${BackendService.apiRoot}/certificates`;
    let url:string = `${BackendService.apiRoot}/certs/request-completion-cert`;
    // let url:string = `${BackendService.apiRoot}/certs/request-participation-cert`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
    .post<Cert>(url, student, {headers: header})
    .pipe(
      catchError(this.handleError)
    );
  }


  getProjects(): Observable<Project[]> {
    // let url:string = `${BackendService.apiRoot}/projects`;
    // let url:string = `${BackendService.apiRoot}/projects/GetAll`;
    let url:string = `${BackendService.apiRoot}/projects`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    return this.httpClient
      .get<Project[]>(url, {
        responseType: 'json',
        headers: header
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  addProject(project: Project) {
    // How to check if a teacher already exists?? - validate against an email probably??
    // let url:string = `${BackendService.apiRoot}/projects`;
    let url:string = `${BackendService.apiRoot}/projects`;
    let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    console.log("Backend:");
    console.log(project);

    return this.httpClient
    .post<Project>(url, project, {headers: header})
    .pipe(
      catchError(this.handleError)
    );
  }

 updateProject(project: Project) {
  let projectJson:string = JSON.stringify(project);
  console.log("Backend - project update: ");
  console.log(project);

  // let url:string = `${BackendService.apiRoot}/projects/${project.id}`;
  let url:string = `${BackendService.apiRoot}/projects/`;
  let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  return this.httpClient
    .put(url, project, {headers: header})
    .pipe(
      catchError(this.handleError)
    );
 }

 updatePassword(password: string): Observable<void | Object> {

  let url:string = `${BackendService.apiRoot}/password/update`
  let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  return this.httpClient
    .put(url, `"${password}"`, {headers : header})
    .pipe(
      catchError(this.handleError)
    );
}

 requestPasswordReset(email : string) {

  // let url:string = `${BackendService.apiRoot}/requestreset`;
  let url:string = `${BackendService.apiRoot}/password/request-reset`;
  // let header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

  console.log("requestPasswordReset submitting " + email);

  return this.httpClient
    // .post(url, email, {headers: header})
    .post(url, `"${email}"`
      ,{ headers: new HttpHeaders({  // set the header values here
          'Content-Type': 'application/json'
      })}
    )//{email: email})//`${email}`)
    .pipe(
      catchError(this.handleError)
    );
 }

 doPasswordReset(password: string, token: string){
  // let url:string = `${BackendService.apiRoot}/reset`;
  let url:string = `${BackendService.apiRoot}/password/perform-reset`;
  console.log("doPasswordReset submitting password " + password + ", and token: " + token);

  return this.httpClient
    .post(url, {
      password: password,
      token: token
    })
    .pipe(
      catchError(this.handleError)
    );


 }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Client-side network error: ', error.error.message);
    } else {
      // Unsuccessful response code. The response body may contain clues
      const errorBodyString = JSON.stringify(error.error);
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${errorBodyString}`);
    }
    // Observable with a user-facing error message
    return throwError(
      'Something went wrong; please try again later.');
  };


  getCategoryName(categoryId){
    if (BackendService.categories && BackendService.categories.length){
      if (BackendService.categories.find(x => x.id === categoryId)) {
        return BackendService.categories.find(x => x.id === categoryId).name;
      } else {
        return `Unknown category: ${categoryId}`;
      }
    } else {
      // prevent an infinite loop
      if(! BackendService.requested['EducationalCategories']) {

        BackendService.requested['EducationalCategories'] = true;
        
        this.getEducationalCategories().subscribe(   
          result => { BackendService.categories = result; this.getCategoryName(categoryId) },
          error => { return "Cannot retrieve categories: " + JSON.stringify(error) }
        )
      }
    }
  }
}

  // -  getCounties_() : Observable<County[]> {
  // -    let url:string = `${BackendService.apiRoot}/counties`;
  // -
  // -    return this.httpClient
  // -      .get<string[]>(url)
  // -      .pipe(
  // -        catchError(this.handleError),
  // -        map(data => this.addIndices(data))
  // -    );
  // -  } 
  // -  private addIndices(input)  {
  // -    return input.map((item, index) =>({
  // -      id: index, value: item
  // -    }));
  // -  }
  