import { Component, Directive, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { BackendService } from '../backend.service';
import { Student, EducationalCategory, Project, Editable } from '../interfaces';
import { concat } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

// export interface ProjectsDropdownItem extends Project{
//   isPreselected: boolean;
// }

export interface ProjectGuiModel {
  project: Project;
  edit:boolean;
}

// @Directive({
//   selector: '[hash]',
// })
// export class HashDirective  {
//   @Input() hash: string;

//   constructor(public ref: ElementRef) {}
// }

@Component({
  selector: 'app-a-projects',
  templateUrl: './a-projects.component.html',
  styleUrls: ['./a-projects.component.css']
})
export class AProjectsComponent implements OnInit {

  @ViewChild('ctdTabset', {static: false}) ctdTabset;
  switchToProjectsTab(){
    this.ctdTabset.select("projectsTab");
  }
  
  // @ViewChildren(HashDirective) private hashes: QueryList<HashDirective>;

  errorMessage: string = null;
  students: Student[];
  categories: EducationalCategory[];
  projects: Project[];
  guiProjects: { project: Project, edit: boolean }[];

  constructor(private backendService: BackendService) { }

  ngOnInit() {
    // On successful response this calls getStudents. On retrieval of students
    // the projects model is set up
    this.getEducationalCategories();
  }

  isSelected(student: Student, project: Project){
    console.warn(student); console.warn(student.projectId);
    // console.warn(project.id);
    if (student.projectId) {console.warn(student.projectId + " <- student.projectId, project id -> " + project.id);}

    return false;
  }


  addToStudentsList(newStudent: Student) {
    this.students.push(newStudent);
  }

  setStudentsProject(student: Student, projectId: number) {

    // if (projectId === null){

    //   let eld = this.hashes.find(x => x.hash === ""+student.id);
    //   let el = this.hashes.find(x => x.hash === String(student.id)).ref;
    //   // console.log("els:", el,els);
    //   // let element = els.ref;
    //   // let elementS = el.ref
    //   el.nativeElement.value = "-1";
    // }

    console.log("setStudentsProject");
    console.log(student); console.log(projectId);
    student.projectId = +projectId;
    this.updateStudent(student);
  }

  updateStudent(student: Student){
    
    this.backendService.updateStudent(
      this.students.find(x => x.id === student.id))
      .subscribe(
        response => {
          console.log("Backend says: student updated");
          console.log(response);
        },
        error => {
          console.log("Backend says: problems updating student");
          console.log(error);
        }
      );
  }
  

  getCurrentProjectName(student: Student) {
    let result = this.projects.find(x => x.id === student.projectId);
    return result ? result.name : null;
  }


  onSaveProject(guiProject: ProjectGuiModel){
    this.setEdit(guiProject, false);
    return this.backendService.updateProject(guiProject.project).subscribe(
      (project: Project) => {
        console.log("A project has just been updated: ");
        console.log(project);
      },
      error => {
        console.log('Project update failed: ');
        console.log(error);
    });
  }

  onCancelEdit(guiProject: ProjectGuiModel) {
    this.setEdit(guiProject, false);
    guiProject.project = JSON.parse(JSON.stringify(
      this.projects.find( x => x.id === guiProject.project.id)
    ));
  }


  addToProjectsList(project: Project) {
    console.log("Adding new project to displayed list of projects:");
    console.log(project);
    this.projects.push(project);
    this.guiProjects.push({project: project, edit: false})
  }

  getProjects() {
    this.backendService.getProjects().subscribe(
      (projects: Project[]) => {
        console.log("Retrieved projects in Projects: " + JSON.stringify(projects));

        this.projects = [{
          id: -1,
          name: "Select a project",
          url: null,
          categoryId: null,
          teacherId: null,
          centreId: null
        }];
        this.projects = this.projects.concat(projects);

        //make and save clones of projects into guiProjects
        this.guiProjects = this.projects.map( x => {
          return { 
            project: JSON.parse(JSON.stringify(x)),
            edit: false
          }}
        );
      },
      error => {
        console.log('Failed to get projects in Projects');
        console.log(error);
    });
  }

  getStudents() {
    this.backendService.getStudents().subscribe(
      (students: Student[]) => {
        console.log("Projects, students: " + JSON.stringify(students));
        this.students = students;
        this.getProjects();
      },
      error => {
        console.log('Failed to get students');
        console.log(error);
    });
  }

  getEducationalCategories() {//res
    this.backendService.getEducationalCategories().subscribe(
      (categories: EducationalCategory[]) => {
        console.log("Projects, categories: " + JSON.stringify(categories));
        this.categories = categories;
        this.getStudents(); // On successful response this calls setUpProjectsDropdownModel
      },
      error => {
        console.log('Failed to get the categories');
        console.log(error);
    });
  }

  setEdit(el: Editable, value: boolean) {
    el.edit = value;
    // console.warn(el);
  }

  // saveProject(project: Project) {
  //   return this.backendService.updateProject(project).subscribe(
  //     (project: Project) => {
  //       console.log("A project has just been updated: ");
  //       console.log(project);
  //     },
  //     error => {
  //       console.log('Project update failed: ');
  //       console.log(error);
  //   });
  // }

  getClassName(categoryId){
    return this.backendService.getCategoryName(categoryId);
  }
  getProjectName(categoryId){
    return this.backendService.getCategoryName(categoryId);
  }

  test(obj:any){
    console.log("test:", obj);
  }
  
  resetProjectDropdown(studentId: string){
    // console.log(this.hashes);
    // console.log(this.hashes.find(x => x.hash === studentId).ref);

    // let el = this.hashes.find(x => x.hash === studentId).ref;
    // el.nativeElement.value = "-1";

  }

}
