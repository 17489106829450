import { Component, OnInit } from '@angular/core';
import { Student, EducationalCategory } from '../interfaces';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-a-students',
  templateUrl: './a-students.component.html',
  styleUrls: ['./a-students.component.css']
})
export class AStudentsComponent implements OnInit {

  fromRegister : string = "empty";

  refreshStudentList(newStudent: Student) {
    // Version A - user experience first: add the new student to the list:
    this.addToListOfStudents(newStudent);
    // Version B - reliability first: retrieve from server:
    // this.getStudents();
  }

  addToListOfStudents(newStudent: Student){
    this.editability.push({id: newStudent.id, editable: false});
    this.students.push(newStudent);
  }

  // private newStudent:Student;
  categories:EducationalCategory[];
  students:Student[];
  private editability;
  constructor(private backendService: BackendService) { }

  ngOnInit() {

    // this.newStudent = {
    //   id : null,
    //   fName: "First name",
    //   lName: "Last name",
    //   gender: null,
    //   teacherId: null,
    //   classId: null,
    //   projectId: null
    // }

    this.getStudents();
    this.getEducationalCategories();//res
  }

  getStudents() {
    this.backendService.getStudents().subscribe(
      (students: Student[]) => {
        console.log("Successful backend response: " + JSON.stringify(students));
        this.students = students;
        // this.editability = Array(this.students.length).fill(
        //   this.students.map()
        // );
        this.editability = students.map(function(x){
          return {id: x.id, editable: false};
        })
        console.log(`s: ${this.students.length}, e: ${this.editability.length}`);
        console.log(this.editability);
      },
      error => {
        console.log('Failed to get students');
        console.log(error);
    });
  }

  getEducationalCategories() {//res
    this.backendService.getEducationalCategories().subscribe(
      (categories: EducationalCategory[]) => {
        console.log("Successful backend response: " + JSON.stringify(categories));
        this.categories = categories;
      },
      error => {
        console.log('Failed to get the categories');
        console.log(error);
    });
  }

  isEditable(id: number){
    return this.editability.find(x => x.id === id).editable;
  }
  switchEditability(id: number){
    let el = this.editability.find(x => x.id === id);
    el.editable = ! el.editable;
    // console.log(`Student ${id} editability set to ${el.editable}`);
    // console.log(this.editability);
  }

  save(id: number) {
    // console.log(`saving ${id}`);
    // this.students.find(x => x.id === id).fName = `${id}Edited`;
    this.switchEditability(id);

    this.backendService.updateStudent(
      this.students.find(x => x.id === id))
      .subscribe( response => console.log(response));
  }

  getClassName(categoryId){
    return this.backendService.getCategoryName(categoryId);
    // return this.categories.find(x => x.id === categoryId).name;
  }

}
