import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';  //ngModel
import { ReactiveFormsModule } from '@angular/forms';


import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { AStudentsComponent } from './a-students/a-students.component';
import { ARhsMenuComponent } from './a-rhs-menu/a-rhs-menu.component';
import { AResourcesComponent } from './a-resources/a-resources.component';
import { ACertificatesComponent } from './a-certificates/a-certificates.component';
import { ACreateStudentComponent } from './a-create-student/a-create-student.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AProjectsComponent } from './a-projects/a-projects.component';
import { RulesComponent } from './a-projects/rules/rules.component';
import { CreateProjectsComponent } from './a-projects/create-projects/create-projects.component';
import { AssignStudentsComponent } from './a-projects/assign-students/assign-students.component';
import { AProfileComponent } from './a-profile/a-profile.component';
import { RegisterComponent } from './register/register.component';
import { AdminComponent } from './admin/admin.component';
import { LandingComponent } from './landing/landing.component';
import { AHeaderComponent } from './a-header/a-header.component';
import { AFooterComponent } from './a-footer/a-footer.component';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { RetrievePasswordComponent } from './login/retrieve-password/retrieve-password.component';
import { AboutComponent } from './about/about.component';
import { EverybodyComponent } from './everybody/everybody.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { RegistrationComponent } from './registration/registration.component';

// import { HashDirective } from './a-projects/a-projects.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AStudentsComponent,
    ARhsMenuComponent,
    AResourcesComponent,
    ACertificatesComponent,
    ACreateStudentComponent,
    PageNotFoundComponent,
    AProjectsComponent,
    RulesComponent,
    CreateProjectsComponent,
    AssignStudentsComponent,
    AProfileComponent,
    RegisterComponent,
    AdminComponent,
    LandingComponent,
    AHeaderComponent,
    AFooterComponent,
    SignInComponent,
    RetrievePasswordComponent,
    AboutComponent,
    EverybodyComponent,
    ResetPasswordComponent,
    RegistrationComponent,
    // HashDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule, //ngModel
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
