import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  message: string = "";

  constructor(private authService:AuthService, private router:Router) { }

  ngOnInit() {
    this.form = new FormGroup ({

      email: new FormControl('',
        [Validators.required, Validators.minLength(5), Validators.email]),

      password: new FormControl('',
        [Validators.required, Validators.minLength(4)])
    });
  }

  onSubmit() {
    // console.log("SignIn.onSubmit: " +this.form.get('email').value + " - " + this.form.get('password').value);
    // const val = this.form.value;
    // console.log("SignIn.onSubmit: " + val.email + ", " + val.password);

    // let result = this.authService.logIn(val.email, val.password);
    // console.log("result:", result);

    const val = this.form.value;
    console.log("SignIn.onSubmit: " + val.email + ", " + val.password);

    this.authService.logIn(val.email, val.password)

    .subscribe(
      (response: any) => {
        console.log(response);
        if (response.token !== undefined) {
          this.authService.setAuth(response.token);
          localStorage.setItem('auth_token', response.token);
          console.log("Successful log-in!");
          this.message = "Login successful";
          this.router.navigateByUrl('/admin/projects');
        }
      },
      error => {
        console.log("Login failed.");
        if (error.status === 404) {
          this.message = "Invalid login. Please try again.";
        } else {
          this.message = "Error, please try again later.";
        }
      });
  }
  
}
