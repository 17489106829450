import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { subscribeOn } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  // canActivate(
  //   route: import("@angular/router").ActivatedRouteSnapshot, 
  //   state: import("@angular/router").RouterStateSnapshot): boolean | 
  //   import("@angular/router").UrlTree | 
  //   import("rxjs").Observable<boolean | 
  //   import("@angular/router").UrlTree> | Promise<boolean | 
  //   import("@angular/router").UrlTree> {
  //     throw new Error("Method not implemented.");
  // }

  //TODO: make it private
  private isLoggedIn : boolean = false;

  constructor (private authService : AuthService, private router: Router) { 
    this.authService.isLoggedIn().subscribe(
      result => {
        this.isLoggedIn = result;
      }
    )
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

    console.log(`AuthGuardService guard check: ${this.isLoggedIn}`);
    // console.log(this.authService.isLoggedIn().subscribe(
    //   result => {
    //     console.log(result);
    //   }
    // ));
    
    // return false;
    // return of(true);

    // return of(false);
    // this.authService.isLoggedIn().subscribe(
    //   response => {
    //     if (response) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //     return false;
    //   }
    // );


    // this.router.navigate(['/public/login/sign-in'], { queryParams: { returnUrl: state.url }});
    if(!this.isLoggedIn){
      this.router.navigate(['/public/login/sign-in']);
    }
    console.log("AuthGuardService just now redirected to /public/login/sign-in");
    return this.isLoggedIn;
    // return true;
  }

  isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }

}
