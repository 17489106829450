import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/custom.validators';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  //Settings
  passwordCannotContain = [ 'password', 'secret' ];
  passwordMinLength = 4;
  passwordMaxLength = 60;

  message: string = "You can now submit the new password";
  submissionBlocked : boolean = false;

  form: FormGroup;
  token: string;

  constructor( private route: ActivatedRoute, private backendService: BackendService ) { }

  ngOnInit() {

    this.token = this.route.snapshot.paramMap.get('token');
    //this.token = new URLSearchParams(window.location.search).get('token');
    
    console.log("resetPassword token",this.token);
    this.form = new FormGroup (
      {
        password: new FormControl('',
        [CustomValidators.required, 
          CustomValidators.minLength(this.passwordMinLength),
          CustomValidators.maxLength(this.passwordMaxLength)]),

        passwordConfirmation: new FormControl('',
        [Validators.required]),
      },

      CustomValidators.compose([
        CustomValidators.mustMatch('password', 'passwordConfirmation')
      ])
    );

    console.log(this.token);
  }

  onSubmit() {
    console.log(this.form.get('password').value + " - " + this.form.get('passwordConfirmation').value);
    console.log(this.token);
    this.backendService.doPasswordReset(
        this.form.get('password').value,
        this.token)
        .subscribe(
          (response) => {
            console.log(response);
            alert("Your password has been updated");
            this.message = "You have successfully updated your password";
            this.submissionBlocked = true;
          },
          error => {
            this.message = "Something went wrong, please try again later";
            console.log("Password reset request failed:");
            console.log(error);
            alert("Something went wrong. Make sure you used password reset email from today.");
          }
        );
  }

}
