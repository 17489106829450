import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EducationalCategory, Project } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/custom.validators';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-create-projects',
  templateUrl: './create-projects.component.html',
  styleUrls: ['./create-projects.component.css']
})
export class CreateProjectsComponent implements OnInit {

  static teacherId: number = 1;

  @Input() categories: EducationalCategory[];
  @Output() onSuccessfulSubmission = new EventEmitter<Project>();
  addProjectForm: FormGroup;
  categoriesDropdown: EducationalCategory[];

  constructor(private backendService: BackendService) { }

  ngOnInit() {


    // TODO: intercept @Input and execute this logic.
    // Do not preserve or use the @Input categories elsewhere.
    this.categoriesDropdown = [{
      id: -1,
      name: "Choose Project Category",
      group: null
    }];
    this.categoriesDropdown = this.categoriesDropdown.concat(this.categories);

    //hack - hardcoded values for removal of CoderDojo age groups.
    this.categoriesDropdown.splice(1,5);
    console.warn("categoriesDropdown", this.categoriesDropdown);

    this.addProjectForm = new FormGroup ({
      name: new FormControl('', CustomValidators.required),
      category: new FormControl(this.categoriesDropdown, [
        CustomValidators.required,
        CustomValidators.excludedValue(this.categoriesDropdown[0].id)
      ]),
      url: new FormControl('')
    });

    this.addProjectForm.get('category').setValue(this.categoriesDropdown[0].id);
  }

  //addProjectForm
  onSubmit() {
    console.log(this.addProjectForm.value);
    console.log(this.addProjectForm.value.category);


    this.backendService.addProject({
      id: null,
      name: this.addProjectForm.value.name,
      url: this.addProjectForm.value.url,
      categoryId: +this.addProjectForm.value.category,
      // teacherId: CreateProjectsComponent.teacherId
      teacherId: null,
      centreId: null
    }).subscribe(
      (result: Project) => {
        console.log("Successful backend response: " + JSON.stringify(result));
        this.onSuccessfulSubmission.emit(result);
      },
      error => {
        console.log('Problems saving  the new project');
        console.log(error);
      }
  );


  }

}
