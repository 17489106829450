import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-resources',
  templateUrl: './a-resources.component.html',
  styleUrls: ['./a-resources.component.css']
})
export class AResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
