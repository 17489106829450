import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendService } from './backend.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private static authUrl = 'http://localhost:8000/api';
  // private static authUrl = 'https://scratch-api.azurewebsites.net';//old azure

  // private static authUrl = 'https://localhost:5001';
  private static authUrl = 'https://ics-scratch-api.azurewebsites.net';

  // private loggedIn: boolean = false;
  private disabledLoggedIn = new BehaviorSubject<boolean>(true);
  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string = null;

  constructor(private httpClient: HttpClient, private backendService: BackendService, private router:Router) {

    let storedToken = localStorage.getItem('auth_token');
    if (storedToken) {
      console.log("TOKEN FROM STORAGE");
      this.setAuth(storedToken);
      // this.router.navigateByUrl('/admin/projects');
    }
  }


  setAuth(token: string) {
       
    if (token) {
      //log in
      this.loggedIn.next(true);
      this.token = token;
      this.backendService.setLoggedIn(true, token);
      setTimeout(() => {
        this.logOut();
      }, 1000 * 60 * 60) // 1 hour
      console.log("LOGGED IN WITH: " + this.token);
    } else {
      //log out
      this.backendService.setLoggedIn(false, null);
      this.loggedIn.next(false);
      this.token = null;
      localStorage.removeItem('auth_token');
      console.log("LOGGED OUT");
      this.router.navigateByUrl('/public/login/sign-in');
    }
  }


  logIn(email:string, password:string ) {

    //?TODO: check if token not expired & alert the user that they are logged in?

    let url:string = `${AuthService.authUrl}/login`;

    return this.httpClient
    .post(url, {email: email, password: password});
    // .subscribe(
    //   (response: any) => {
    //     console.log(response);
    //     if (response.token !== undefined) {
    //       this.setAuth(response.token);
    //       localStorage.setItem('auth_token', response.token);
    //       console.log("Successful log-in!");
    //       this.router.navigateByUrl('/admin/projects');
    //       return "success";
    //     }
    //   },
    //   error => {
    //     console.log("Login failed.");
    //     if (error.status === 404) {
    //       return "not found";
    //     } else {
    //       return "unknown error";
    //     }
    //   });
  }

  logOut() {
    this.setAuth(null);
  }

  isLoggedIn() : Observable<boolean> {
    return this.loggedIn.asObservable();
    // disable auth
    // return this.disabledLoggedIn.asObservable();
  }

}
