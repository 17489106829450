import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { County, Teacher, Organisation, UserDetails } from '../interfaces';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../custom.validators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGuardService } from '../auth-guard.service';
import { ScratchConfig as ScratchConfig } from '../config';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  counties: County[] = [];
  form: FormGroup;
  mode:string = "";



  updatable:boolean = false;
  switchUpdatable() {
    this.updatable = ! this.updatable;
  }
  displayOrganisationInputs:boolean = true;
  switchDisplayOrganisationInputs() {
    this.displayOrganisationInputs = ! this.displayOrganisationInputs;
  }
  populateOrganisationDetailsModel() {
    //this.form.value.organisationDetails.name.
    let od = this.form.get('organisationDetails') as FormGroup;
    
    // od.get('email').setValue("ha@d.d");
    od.get('name').setValue("ha");
    od.get('rollNo').setValue("ha@d.d");
    od.get('address1').setValue("had.d");
    od.get('address2').setValue("address line 2");
    od.get('address3').setValue("address line 3");
    od.get('counties').setValue(this.counties[1]);
  }

  //Settings
  passwordCannotContain = [ 'password', 'secret' ];
  passwordMinLength = 4;
  passwordMaxLength = 50;
  addressLine1MinLength = 3;//not in use now

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authGuardService: AuthGuardService, 
    private backendService: BackendService
  ){}

  ngOnInit() {
    this.mode = this.route.snapshot.paramMap.get('mode');
    console.log("register.component.ngOnInit() mode: ", this.mode);
    this.updatable = ( this.mode === 'update') ? true : false;

    this.buildForm();
    this.getCounties();
    // this.countiesControl.setValue(this.counties);
    this.countiesControl.setValidators([
      //.validator returns a composite validator containing all currently set validators
      this.countiesControl.validator,
      // CustomValidators.excludedValue(this.countiesControl.value),
      CustomValidators.excludedId(-1)
    ]);
    // this.countiesControl.setValue(this.counties[3]);

    if (this.updatable && this.authGuardService.isUserLoggedIn()) {
      this.populateFormWithUserDetails();
    }
  }

  populateFormWithUserDetails(){

    console.log("# # #  # #  # # #  # # getting user details # #  # # #  #");

    this.backendService.getUserDetails().subscribe(
      (details: UserDetails) => {

        delete details.personalDetails.id;
        delete details.personalDetails.password;
        delete details.personalDetails.organisationId;
        // Less strict patchValue in place of setValue would work withouth the above deletions 
        this.personalDetails.setValue(details.personalDetails);

        // this updates all controls except "counties"
        (this.form.get('organisationDetails') as FormGroup).patchValue(details.organisationDetails);
        // set correct county
        this.countiesControl.setValue(this.counties[details.organisationDetails.countyId]);

      },
      error => {
        console.log('Failed to get user details: ');
        console.log(error);
    });
  }

  getUserData() {

  }


  getCounties(){
    this.backendService.getCounties().subscribe(
      serviceResponse => {

        console.log("Successfully retrieved counties");
        this.counties = serviceResponse;
        this.counties.unshift({id: -1, value: "Please select"});
        console.log(this.counties);
      
        this.countiesControl.setValue(this.counties[0]);


        // //Add a new validator to the countiesControl
        // this.countiesControl.setValidators(
        //   [
        //     //.validator returns a composite validator containing all currently set validators
        //     this.countiesControl.validator,
        //     CustomValidators.excludedValue(this.counties[0])
        //   ]);
        // // this.organisationDetails.get('counties').setValue(this.counties[0]);
        // console.log(this.countiesControl);
      },
      error => {
        console.log('Failed to get counties');
        console.log(error);
    });

    // set the default value - this has to be done AFTER the additional validator
    // (excludedValue) has been added for that validator to work. 
    // this.countiesControl.setValue(this.counties[0]);
  }


  //Ensure easy access form the template
  get personalDetails() { return this.form.get('personalDetails') as FormGroup };
  get firstName() { return this.personalDetails.get('fName') };
  get surname() { return this.personalDetails.get('lName') };
  get phone() { return this.personalDetails.get('phone') };
  get email() { return this.personalDetails.get('email') };

  get password() { return this.form.get('password')};
  get retypedPassword() { return this.form.get('retypedPassword')};
  
  get organisationDetails() { return this.form.get('organisationDetails') as FormGroup };
  // get organisation() { return this.organisationDetails.get('organisation') };
  // get rollNumber() { return this.organisationDetails.get('rollNumber') };
  // get addressLine1() { return this.organisationDetails.get('addressLine1') };
  // get addressLine2() { return this.organisationDetails.get('addressLine2') };
  // get addressLine3() { return this.organisationDetails.get('addressLine3') };
  // get town() { return this.organisationDetails.get('town') };
  // // added "Control" because of the conflict with the counties field
  get countiesControl() { return this.organisationDetails.get('counties') };

  buildForm() {
    this.form = this.formBuilder.group({

      personalDetails: this.formBuilder.group({

        fName: ['',
        [
          CustomValidators.required,
          // CustomValidators.onlyLettersSpacesDotsAndHypens
        ]],
        lName: ['',
        [
          CustomValidators.required,
          // CustomValidators.onlyLettersSpacesDotsAndHypens
        ]],
        email: ['',[
          CustomValidators.required,
          CustomValidators.email
        ]],
        phone: ['',
        [
          CustomValidators.required,
          CustomValidators.onlyNumbersSpacesBracketsAndHypens
        ]]
      }),

      password: [ '',
      [
        CustomValidators.required,
        CustomValidators.cannotContain(this.passwordCannotContain),
        CustomValidators.minLength(this.passwordMinLength),
        CustomValidators.maxLength(this.passwordMaxLength)]],
      retypedPassword: ['', 
      [
        CustomValidators.required]],


      organisationDetails: this.formBuilder.group({
        name: ['',
        [
          CustomValidators.required,
          // CustomValidators.onlyLettersSpacesDotsAndHypens
        ]],
        rollNo: [''],
        // email: [''],
        address1:['',
          [
            CustomValidators.required,
            // CustomValidators.minLength(this.addressLine1MinLength),
            // CustomValidators.onlyLettersSpacesDotsDigitsAndHypens
          ]],
        address2:[''],//, CustomValidators.onlyLettersSpacesDotsDigitsAndHypens],
        address3:[''],//, CustomValidators.onlyLettersSpacesDotsDigitsAndHypens],
        town:[''],
        counties:['', CustomValidators.required]
      })
      
    },
      
    {
      validator: CustomValidators.compose([
        CustomValidators.mustMatch('password', 'retypedPassword')
      ])
    });
  }

  updateSection($sectionId :string) {
    switch($sectionId) {
      case 'personalDetails':
        console.warn("Updating personal details");
        this.backendService.updateTeacher(this.teacherFromForm(this.form))
        .subscribe(
          (response: Teacher) => {
            alert("Successfully updated your personal details");
            console.log("Successfully updated teacher:");
            console.log(response);
          },
          error => {
            console.log("Unable to update teacher:");
            console.log(error);
          }
        );;
      break;
      case 'organisationDetails':
        console.warn("Updating organisation details");
        this.backendService.updateOrganisation(this.organisationFromForm(this.form))
        .subscribe(
          (response: Organisation) => {
            alert("Successfully updated details of your organisation");
            console.log("Organisation successfully updated: ");
            console.log(response);
          },
          error => {
            console.log("Organisation update failed: ");
            console.log(error);
          }
        );
      break;
      case 'password':
        console.warn("Updating password");
        this.backendService.updatePassword(this.form.value.password)
        .subscribe(
          () => {
            alert("Password successfully updated!");
            console.log("Password successfully updated!");
          },
          error => {
            console.log("Password update failed");
            console.log(error);
          }
        );
      break;
    }
  }
  
  private teacherFromForm(form: FormGroup): Teacher {
    return {
      id: null,
      fName: form.value.personalDetails.fName,
      lName: form.value.personalDetails.lName,
      email: form.value.personalDetails.email,
      password: form.value.password,
      phone: form.value.personalDetails.phone,
      organisationId: null}
  }

  private organisationFromForm(form: FormGroup): Organisation {
    return {
      id: null,
      name: form.value.organisationDetails.name,
      rollNo: form.value.organisationDetails.rollNo,
      contactEmail: form.value.personalDetails.email,
      contactName: `${form.value.personalDetails.fName} ${form.value.personalDetails.lName}`,
      contactPhone: form.value.personalDetails.phone,
      address1: form.value.organisationDetails.address1,
      address2: form.value.organisationDetails.address2,
      address3: form.value.organisationDetails.address3,
      town: form.value.organisationDetails.town,
      countyId: form.value.organisationDetails.counties.id
    }
  }

  onSubmit(form: FormGroup){
    console.log("form",form);
    // let newTeacher : Teacher = form.value;

    let newTeacher = this.teacherFromForm(form);
    // let newTeacher: Teacher = {
    //   id: null,
    //   fName: form.value.personalDetails.fName,
    //   lName: form.value.personalDetails.lName,
    //   email: form.value.personalDetails.email,
    //   password: form.value.password,
    //   phone: form.value.personalDetails.phone,
    //   organisationId: null
    // }

    let organisation: Organisation = this.organisationFromForm(form);
    // let organisation: Organisation = {
    //   id: null,
    //   name: form.value.organisationDetails.name,
    //   rollNo: form.value.organisationDetails.rollNo,
    //   address1: form.value.organisationDetails.address1,
    //   address2: form.value.organisationDetails.address2,
    //   address3: form.value.organisationDetails.address3,
    //   town: form.value.organisationDetails.town,
    //   countyId: form.value.organisationDetails.counties.id
    // }

    console.log("teacher", newTeacher);
    console.log("organisation", organisation);
    console.log(form.value.organisationDetails.counties);

    this.backendService.addOrganisation(organisation)
      .subscribe(
      (response: Organisation) => {
        console.log("Successfully saved a new organisation:");
        console.log(response);

        newTeacher.organisationId = response.id;
        console.log("Now sending a new teacher:");
        console.log(newTeacher);

        this.backendService.addTeacher(newTeacher)
        .subscribe(
          (response: Teacher) => {
            console.log("Successfully saved a new teacher:");
            console.log(response);
            alert("Registration completed, you can log-in now");
            this.router.navigate(['/public/login/sign-in']);
          },
          error => {
            console.log("Unable to save a new teacher:");
            console.log(error);
            alert("Something went wrong, please try again later.");
          }
        );
      },
      error => {
        console.log("Login failed.");
        if (error.status === 409) {
          // window.alert(`This email is already in use. To register with this email (${error.error}) please contact us via ${ScratchConfig.EMAIL}`);
          window.alert(`You are already registered! There is no need to register again - just log-in using this email (${error.error})\nTo reset your password please click on Forgot Password`);
        } else {
          window.alert(`Something went wrong, please try again later`);
        }
      }
    );
  }

  log(anything: any) {
    console.log(anything);
  }

}
