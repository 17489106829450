import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ACertificatesComponent } from './a-certificates/a-certificates.component';
import { AResourcesComponent } from './a-resources/a-resources.component';
import { AStudentsComponent } from './a-students/a-students.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AProjectsComponent } from './a-projects/a-projects.component';
import { AssignStudentsComponent } from './a-projects/assign-students/assign-students.component';
import { CreateProjectsComponent } from './a-projects/create-projects/create-projects.component';
import { RulesComponent } from './a-projects/rules/rules.component';
import { AProfileComponent } from './a-profile/a-profile.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { AdminComponent } from './admin/admin.component';
import { RetrievePasswordComponent } from './login/retrieve-password/retrieve-password.component';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { AboutComponent } from './about/about.component';
import { EverybodyComponent } from './everybody/everybody.component';
import { AuthGuardService } from './auth-guard.service';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { RegistrationComponent } from './registration/registration.component';


const routes: Routes = [

  {path: "reset-password/:token", component: ResetPasswordComponent},

  {path: 'registration', component: RegisterComponent},
  {path: 'register', component: RegisterComponent},

  {path: 'public', component: EverybodyComponent,
    children: [
      {path: 'login', component: LoginComponent,
        children: [
          {path: 'sign-in', component: SignInComponent },
          {path: 'retrieve-password', component: RetrievePasswordComponent},
          {path: 'reset-password', component: ResetPasswordComponent},
        ]
      },
      // {path: 'about', component: AboutComponent},
      {path: 'rules', component: RulesComponent},
      // {path: 'registration', component: RegisterComponent},
    ]
  },
  // {path: 'about', component: AboutComponent},
  {path: 'landing', component: LandingComponent},
  {path: 'admin', component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {path: 'update', component: LoginComponent},
      {path: 'students', component: AStudentsComponent},
      // {path: 'resources', component: AResourcesComponent},
      {path: 'certificates', component: ACertificatesComponent},
      {path: 'projects', component: AProjectsComponent},
      {path: 'manage-profile/:mode', component: RegisterComponent},
    ]
  },

  {path: '', component: LandingComponent, pathMatch: 'full'},

  {path: '**', component: PageNotFoundComponent}, // wildcard 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // or 'top'
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64] // [x, y] - adjust scroll offset
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
