import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-retrieve-password',
  templateUrl: './retrieve-password.component.html',
  styleUrls: ['./retrieve-password.component.css']
})
export class RetrievePasswordComponent implements OnInit {

  email: FormControl;
  passwordSubmitted: boolean = false;

  constructor(private backendService:BackendService) { }

  ngOnInit() {
      this.email = new FormControl('', [Validators.required, Validators.minLength(3), Validators.email]);
  }


  onSubmit() {

    // this.email.disable();

    this.backendService.requestPasswordReset(this.email.value).subscribe(
      (response) => {
        // this.passwordSubmitted = true;
        console.log("requestPasswordReset request returned");
        alert("Password reset email was sent to the submitted email if you used this email to register with us.");
      },
      error => {
        console.log("Password reset request returned with error:");
        console.log(error);
      }
    );
  }

}
