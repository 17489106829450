import { ValidatorFn, AbstractControl, Validators, FormGroup } from "@angular/forms";

export { CustomValidators };

class CustomValidators extends Validators {
    constructor(validators: Validators) {
        super();
    }

    // these functions return Angular validators that accept only AbstractControl as an arguments
    // so that it is possible to use additional aguments within them.
    static cannotContain (needle: string | string[]): ValidatorFn {

        return (control: AbstractControl):  { [key: string]: boolean } | null => {

            if ( ! control.value) { return null; }
            if ( typeof needle === 'string' ) { needle = [needle]; }

            let found:boolean = false;

            needle.forEach(element => {
                if (control.value.toUpperCase().includes(element.toUpperCase())) {
                    found = true;
                }
            });
            return found ? {containsForbiddenString : true} : null;
        }
    }

    static rangeValidator(min: number, max: number): ValidatorFn {

        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if (control.value !== undefined && 
                    (isNaN(control.value) || control.value < min || control.value > max)) {
                return { outOfRange : true };
            }
            return null;
        };
    }

    static excludedValue(value): ValidatorFn {

        return (control: AbstractControl): { [key: string]: boolean } | null => {
            // console.log("control.value", control.value);
            // console.log("value", value);
            if (control.value !== undefined && control.value === value) {
                return { excludedValue : true };
            }
            return null;
        };
    }

    static excludedId(id:number) : ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if (control.value !== undefined && control.value.id === id) {
                return { excludedValue : true };
            }
            return null;
        };
    }

    // // not good
    // static excludedOptionIndex(options:any[], index): ValidatorFn {

    //     return (control: AbstractControl): { [key: string]: boolean } | null => {

    //         console.log("excludedOptionIndex");
    //         console.log(control.value);
    //         console.log(options.indexOf( x => (x.id === control.value.id)));
    //         console.log(options);

    //         if (control.value !== undefined && options.indexOf(control.value) === index) {
    //             return { excludedOption : true };
    //         }
    //         return null;
    //     };
    // }

    static mustMatch (controlNameA: string, controlNameB: string): ValidatorFn {

        return (formGroup: FormGroup): { [key: string]: boolean } | null => {

            const controlA = formGroup.controls[controlNameA];
            const controlB = formGroup.controls[controlNameB];

            //TODO: null checks, etc
            if (!controlA.value || !controlB.value) { return null }

            if (controlA.value !== controlB.value) {
                //generate arguments-based "error name" to make the validator reusable within a formGroup
                return { [`${controlNameA}_DoNotMatch_${controlNameB}`]: true };
            }
            return null;
        };
    }

    static mustDiffer (controlNameA: string, controlNameB: string): ValidatorFn {
        return (formGroup: FormGroup): { [key: string]: boolean } | null => {

            const controlA = formGroup.controls[controlNameA];
            const controlB = formGroup.controls[controlNameB];

            //TODO: null checks...
            if (!controlA.value || !controlB.value) { return null }

            if (controlA.value === controlB.value) {
                //generate arguments-based "error name" to make the validator reusable within a formGroup
                return { [`${controlNameA}_SameAs_${controlNameB}`]: true };
            }
            return null;
        };
    }

    static onlyNumbersSpacesBracketsAndHypens(ctrl: AbstractControl): { [key: string]: boolean } | null {
        let pattern = new RegExp('[^0-9 .-]');
        if (ctrl.value && pattern.test(ctrl.value)) {
            return { nonNumberSpaceBracketOrHypenCharacter: true }
        }
        return null;
    }

    static onlyLettersSpacesDotsAndHypens(ctrl: AbstractControl): { [key: string]: boolean } | null {
        let pattern = new RegExp('[^A-Za-z .-]'); //new RegExp('^A-Za-z -'); //[^A-Za-z0-9_]
        if (ctrl.value && pattern.test(ctrl.value)) {
            return { nonLetterSpaceDotOrHypenCharacter: true }
        }
        return null;
    }
    
    static onlyLettersSpacesDotsDigitsAndHypens(ctrl: AbstractControl): { [key: string]: boolean } | null {
        let pattern = new RegExp('[^A-Za-z0-9 .-]');
        if (ctrl.value && pattern.test(ctrl.value)) {
            return { nonLetterSpaceDotDigitOrHypenCharacter: true }
        }
        return null;
    }
    
    


    // static differentQuestionsValidator(ctrl: AbstractControl) {
    //     if (!ctrl.get('question1').value || !ctrl.get('question2').value) {
    //       return null
    //     }
    //     if (ctrl.get('question1').value === ctrl.get('question2').value) {
    //       return { sameQuestionSelectedTwice: true };
    //     } else {
    //       return null;
    //     }
    //   }

}


