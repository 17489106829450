import { Component, OnInit } from '@angular/core';
import { Student, EducationalCategory } from '../interfaces';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-a-certificates',
  templateUrl: './a-certificates.component.html',
  styleUrls: ['./a-certificates.component.css']
})
export class ACertificatesComponent implements OnInit {

  students: Student[];

  constructor( private backendService: BackendService) { }


  ngOnInit() {
    this.getStudents();
  }

  getStudents() {
    this.backendService.getStudents().subscribe(
      (students: Student[]) => {
        console.log("Successful backend response: " + JSON.stringify(students));
        this.students = students;
        // this.editability = Array(this.students.length).fill(
        //   this.students.map()
        // );
      },
      error => {
        console.log('Failed to get students');
        console.log(error);
    });
  }

  requestCert(studentId:number){
    console.log(`requesting cert for student ${studentId}`);

    let student : Student = this.students.find(x => x.id === studentId);
    // student.cert_requested_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    
    // this.backendService.updateStudent(student)
    //   .subscribe( response => console.log(response));
    
    this.backendService.requestCert(student)
    .subscribe( response => {
      // response.sentAt = response.sentAt.replace(' ', 'T')
      console.log(response);
      this.students.find(x=>x.id == response.studentId).cert = response;

      // FileSaver.saveAs(pdf, filename);
    });

    // let pdf = new Blob(response.data, { type: "application/pdf" });
    // var link = document.createElement('a');
    // link.href = window.URL.createObjectURL(pdf);;
    // link.click(); 
  }

}
